<template>
  <div>
    <!-- modal -->
    <b-modal
      id="mdlNuevoDocumentoDigital"
      v-model="showModalUploadFile"
      :title="tituloModal"
      ok-title="Aceptar"
      cancel-title="Cancelar"
      ok-variant="primary"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      centered
      @ok="okModal"
      @keydown="onSubmit"
      @close="closeModal"
      @cancel="closeModal"
    >
      <!-- form -->
      <validation-observer ref="modalUploadFileForm">
        <b-form>
          <b-overlay
            :show="isSaveOverlay"
            rounded="sm"
          >
            <div>
              <b-row>
                <b-col
                  v-if="!openMode"
                  md="12"
                >
                  <b-form-group
                    id="filAdjunto"
                    label="Archivo"
                  >
                    <b-form-file
                      ref="file-uploader"
                      v-model="file"
                      placeholder=""
                      :accept="config.extensionesAceptadas"
                      browse-text="Examinar"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="tblAdjuntos"
                :items="tableDataAdjuntos"
                :busy="isBusyAdjuntos"
                :fields="fieldsAdjuntos"
                outlined
                small
                show-empty
                :no-border-collapse="true"
                responsive
                empty-text="No hay archivo adjunto cargado"
              >
                <template v-slot:table-busy>
                  <div class="text-center">
                    <b-spinner />
                  </div>
                </template>
                <template #cell(adjuntoSize)="row">
                  {{ getMBfromBytes(row.item.adjuntoSize) }} MB
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-if="!openMode"
                    v-b-tooltip.hover.right
                    variant="danger"
                    size="sm"
                    title="Eliminar adjunto"
                    @click="deleteAdjunto(row.index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!--title="Descargar adjunto"-->
                  <b-button
                    v-if="openMode"
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    :disabled="row.item.isDownloading"
                    @click="descargarAdjunto(row)"
                  >
                    <font-awesome-icon
                      v-if="!row.item.isDownloading"
                      icon="fa-solid fa-file-arrow-down"
                    />
                    <span
                      v-if="row.item.isDownloading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-overlay>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          squared
          variant="outline-secondary"
          @click="closeModal"
        >
          {{ tituloBotonCerrar }}
        </b-button>

        <b-button
          v-if="!openMode"
          size="sm"
          squared
          variant="outline-secondary"
          @click="okModal"
        >
          Aceptar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, max,
} from '@validations'

import { solicitudResource } from '@/services/solicitudResource'
import { fileManagement } from '@/utils/fileManagement'
// import { saveAs } from 'file-saver'

export default {
  name: 'ModalUploadFile',
  components: {
    // ValidationProvider,
    // ValidationObserver,
    // BCardCode,
  },
  props: {
    showModalUploadFile: {
      type: Boolean,
      required: true,
      default: false,
    },
    adjuntosList: {
      type: Array,
      required: false,
      default: null,
    },
    identificadorEmit: {
      type: String,
      required: false,
      default: null,
    },
    itemSolicitud: {
      type: Object,
      required: true,
      default: null,
    },
    tituloModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataSend: '',
      isSaveOverlay: false,
      config: {
        tamanioMaximoAdjunto: 10, // MB
        extensionesAceptadas: '.pdf, .doc, .txt, .png, .jpg, .jpng',
      },
      required,
      confirmed,
      max,
      showBuscarCasoModal: false,
      file: null,
      tableDataAdjuntos: [],
      isBusyAdjuntos: false,
      fieldsAdjuntos: [
        {
          key: 'nombreAdjunto', label: 'Nombre',
        }, {
          key: 'adjuntoSize', label: 'Tamaño',
        },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },
  computed: {
    openMode() {
      return this.itemSolicitud.solicitudGenerada
    },
    tituloBotonCerrar() {
      return this.openMode ? 'Cerrar' : 'Cancelar'
    },
  },
  watch: {
    file() {
      if (this.file !== null) {
        let extensionArchivo = this.file.name.split('.').pop()
        if (extensionArchivo !== null) {
          extensionArchivo = extensionArchivo.toLowerCase()
        }
        //  && extensionArchivo === 'pdf'
        if (extensionArchivo !== null && this.config.extensionesAceptadas.includes(extensionArchivo)) {
          if (this.getMBfromBytes(this.file.size) > 0 && this.getMBfromBytes(this.file.size) <= this.config.tamanioMaximoAdjunto) {
            if (this.file.name.length > 100) {
              this.toast('warning', null, 'El nombre del archivo no debe superar los 100 caracteres')
            } else {
              const adjuntoDto = {
                id: null,
                nombreAdjunto: this.file.name,
                adjuntoSize: this.file.size,
                adjunto: this.file,
                tipoAdjunto: this.file.type,
              }
              this.tableDataAdjuntos.push(adjuntoDto)
            }
          } else if (this.file.size === 0) {
            this.toast('warning', null, 'El archivo a adjuntar no debe estar vacío')
          } else {
            this.toast('warning', null, `El archivo a adjuntar debe tener un tamaño inferior a ${this.config.tamanioMaximoAdjunto} Mb`)
          }
        } else {
          this.toast('warning', null, `Solo se permite adjuntar archivos en los siguientes formatos ${this.config.extensionesAceptadas}`)
        }
        this.clearUpload()
      }
    },
  },
  created() {
    if (this.adjuntosList.length > 0) {
      this.adjuntosList.forEach(adjuntoDto => {
        this.tableDataAdjuntos.push(adjuntoDto)
      })
    } else if (this.itemSolicitud.solicitudGenerada) {
      this.getAdjuntosInfo(this.itemSolicitud.id)
    }
  },
  methods: {
    onSubmit() {

    },
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },

    closeModal(bvModalEvt) {
      this.$emit('closemodal')
      bvModalEvt.preventDefault()
    },
    okModal(bvModalEvt) {
      this.validationFormModalUploadFile()
      bvModalEvt.preventDefault()
    },
    validationFormModalUploadFile() {
      this.$refs.modalUploadFileForm.validate().then(success => {
        if (success) {
          if (!this.isSaveOverlay) {
            this.$emit('contentModalUploadFile', { adjuntos: this.tableDataAdjuntos, identificador: this.identificadorEmit })
            /* if (this.tableDataAdjuntos.length === 0) {
              this.toast('warning', null, 'Debe adjuntar un archivo para guardar')
            } else {
              // this.$emit('contentModalUploadFile', this.tableDataAdjuntos)
              this.$emit('contentModalUploadFile', { adjuntos: this.tableDataAdjuntos, identificador: this.identificadorEmit })
            } */
          }
        }
      })
    },
    descargarAdjunto(row) {
      this.tableDataAdjuntos[row.index].isDownloading = true
      solicitudResource().getAdjuntoByte(row.item.id).then(resBytes => {
        fileManagement().getAdjunto(resBytes, row.item.nombreAdjunto, row.item.tipoAdjunto)
        // const blob = new Blob([resBytes], { type: 'application/x-www-form-urlencoded' })
        // saveAs(blob, row.item.nombreAdjunto)
        this.tableDataAdjuntos[row.index].isDownloading = false
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.tableDataAdjuntos[row.index].isDownloading = false
        })
    },
    getAdjuntosInfo(idSolicitud) {
      this.isBusyAdjuntos = true
      solicitudResource().getInfoAdjuntos(idSolicitud).then(results => {
        this.tableDataAdjuntos = [...results]
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.isBusyAdjuntos = false
        })
    },
    deleteAdjunto(index) {
      this.tableDataAdjuntos.splice(index, 1)
    },
    clearUpload() {
      this.$refs['file-uploader'].reset()
      this.file = null
    },
  },
}
</script>
