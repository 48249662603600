<template>
  <div class="text-right">
    <span v-if="tipo === 'CONTADOR'">{{ actual }}/{{ maximo }} caracteres</span>
    <span v-if="tipo === 'RESTANTE'">{{ restantes }} caracteres restantes</span>
  </div>
</template>
<script>
export default {
  name: 'Contador',
  components: {
  },
  props: {
    maximo: {
      type: Number,
      required: false,
      default: 0,
    },
    texto: {
      type: String,
      required: false,
      default: '',
    },
    tipo: {
      type: String,
      required: true,
      default: 'CONTADOR',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    actual() {
      if (this.texto && this.texto !== null) {
        return this.texto.length
      }
      return 0
    },
    restantes() {
      if (this.texto && this.texto !== null) {
        return this.maximo - this.texto.length
      }
      return this.maximo
    },
  },
  created() {
  },
  methods: {
  },
}
</script>
