var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formSolicitudQuincho"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-overlay',{attrs:{"show":_vm.isWorking,"variant":_vm.overlayConfig.variant,"opacity":_vm.overlayConfig.opacity,"blur":_vm.overlayConfig.blur},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [(_vm.isWorking)?_c('div',[_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"spinner-border p-5"})]),_c('div',{staticStyle:{"text-align":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.overlayConfig.message))])])]):_vm._e()]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"m-0",attrs:{"md":"12"}},[_c('h4',[_c('b',[_vm._v("Solicitud de "+_vm._s(_vm.beneficio.nombre))])])])],1),_c('b-row',{staticClass:"p-0 "},[_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[(_vm.beneficio.mostrarRequisitos)?_c('b-card-actions',{staticClass:"p-0",attrs:{"title":"Requisitos para el beneficio","action-collapse":""}},[_c('b-form-textarea',{attrs:{"id":"textAreaRequisitos","value":_vm.beneficio.requisitos,"no-auto-shrink":"","placeholder":"Requisitos","disabled":"","rows":"6"}})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":""}},[_c('b-form-group',{attrs:{"label-for":"fechaReserva","label":"Fecha de reserva"}},[_c('validation-provider',{attrs:{"name":"fechaReserva","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-datepicker',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Fecha de solicitud'),expression:"'Fecha de solicitud'",modifiers:{"hover":true}}],attrs:{"id":"sltDesde","locale":"es-AR","min":_vm.maxDate,"date-format-options":{ day: 'numeric' , year: 'numeric', month: 'numeric'},"label-help":"Use las teclas de flecha para navegar por el calendario","label-current-month":"Mes actual","label-next-month":"Mes siguiente","label-next-year":"Año siguiente","label-no-date-selected":"Reserva","label-prev-month":"Mes anterior","label-prev-year":"Año anterior","state":errors.length > 0 ? false : null},model:{value:(_vm.formSolicitudQuinchoDto.fechaReserva),callback:function ($$v) {_vm.$set(_vm.formSolicitudQuinchoDto, "fechaReserva", $$v)},expression:"formSolicitudQuinchoDto.fechaReserva"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"telefonoContacto","label":"Teléfono contacto"}},[_c('validation-provider',{attrs:{"name":"telefonoContacto","rules":"required|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"inputDni","state":errors.length > 0 ? false:null,"autocomplete":"off","type":"number","placeholder":""},on:{"input":function (e) { return _vm.checkOnlyNumber(e); }},model:{value:(_vm.formSolicitudQuinchoDto.telContacto),callback:function ($$v) {_vm.$set(_vm.formSolicitudQuinchoDto, "telContacto", $$v)},expression:"formSolicitudQuinchoDto.telContacto"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio. ")]):_vm._e(),(failedRules.max)?_c('small',{staticClass:"text-danger"},[_vm._v("Máximo 10 caracteres.")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"cantidadPersonas","label":"Cantidad de personas"}},[_c('validation-provider',{attrs:{"name":"cantidadPersonas","rules":"required|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"inputDni","state":errors.length > 0 ? false:null,"autocomplete":"off","type":"number","placeholder":""},on:{"input":function (e) { return _vm.checkOnlyNumber(e); }},model:{value:(_vm.formSolicitudQuinchoDto.cantPersonas),callback:function ($$v) {_vm.$set(_vm.formSolicitudQuinchoDto, "cantPersonas", $$v)},expression:"formSolicitudQuinchoDto.cantPersonas"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio. ")]):_vm._e(),(failedRules.max)?_c('small',{staticClass:"text-danger"},[_vm._v("Máximo 4 caracteres.")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"horario","label":"Horario"}},[_c('validation-provider',{attrs:{"name":"horario","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-select',{attrs:{"id":"selectHorario","options":_vm.listaHorario,"state":errors.length > 0 ? false:null},model:{value:(_vm.formSolicitudQuinchoDto.horario),callback:function ($$v) {_vm.$set(_vm.formSolicitudQuinchoDto, "horario", $$v)},expression:"formSolicitudQuinchoDto.horario"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio. ")]):_vm._e()],1)}}])})],1)],1)],1),_c('b-row',{staticClass:"p-0 "},[_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[_c('b-card-actions',{staticClass:"p-0",attrs:{"title":"Observaciones","action-collapse":""}},[_c('validation-provider',{attrs:{"name":"observa","rules":"required|min:4|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-textarea',{attrs:{"id":"textAreaRequisitos","no-auto-shrink":"","placeholder":"Escriba aquí alguna observación","state":errors.length > 0 ? false:null,"rows":"4","maxlength":"1000"},model:{value:(_vm.formSolicitudQuinchoDto.observaciones),callback:function ($$v) {_vm.$set(_vm.formSolicitudQuinchoDto, "observaciones", $$v)},expression:"formSolicitudQuinchoDto.observaciones"}}),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio ")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("Minimo 4 caracteres ")]):_vm._e()]),_c('b-col',{attrs:{"md":"6"}},[_c('contador',{attrs:{"maximo":1000,"texto":_vm.formSolicitudQuinchoDto.observaciones,"tipo":'CONTADOR'}})],1)],1)],1)}}])})],1)],1)],1),_c('br')],1)]),_c('hr'),_c('b-row',[_c('div',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"btnBackPanelBeneficios","title":"Volver al panel de benficios","variant":"light","squared":"","disabled":_vm.isWorking},on:{"click":function (){ return _vm.backPanelBeneficios(); }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}})],1)],1)],1),_c('div',{staticClass:"align-self-end ml-auto"},[_c('b-col',{attrs:{"md":"12"}},[(_vm.showSave)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","squared":"","title":"Generar solicitud","type":"submit","disabled":_vm.isWorking},on:{"click":function($event){$event.preventDefault();return _vm.validationFormSolicitudQuincho($event)}}},[(!_vm.isWorking)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-floppy-disk"}}):_vm._e(),(_vm.isWorking)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e()],1):_vm._e()],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }