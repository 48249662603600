<template>
  <div>
    <validation-observer ref="formSolicitudQuincho">
      <b-form @submit.prevent="onSubmit">
        <b-overlay
          :show="isWorking"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div v-if="isWorking">
              <div style="text-align: center">
                <span class="spinner-border p-5" />
              </div>
              <div style="text-align: center">
                <h4>{{ overlayConfig.message }}</h4>
              </div>
            </div>
          </template>
          <div>
            <b-row>
              <b-col
                md="12"
                class="m-0"
              >
                <h4>
                  <b>Solicitud de {{ beneficio.nombre }}</b>
                </h4>
              </b-col>
            </b-row>

            <b-row class="p-0 ">
              <b-col
                md="12"
                class="p-0"
              >
                <b-card-actions
                  v-if="beneficio.mostrarRequisitos"
                  class="p-0"
                  title="Requisitos para el beneficio"
                  action-collapse
                >
                  <b-form-textarea
                    id="textAreaRequisitos"
                    :value="beneficio.requisitos"
                    no-auto-shrink
                    placeholder="Requisitos"
                    disabled
                    rows="6"
                  />
                </b-card-actions>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="">
                <b-form-group
                  label-for="fechaReserva"
                  label="Fecha de reserva"
                >
                  <validation-provider
                    name="fechaReserva"
                    rules="required|min:8"
                  >

                    <div slot-scope="{ errors, failedRules }">
                      <b-form-datepicker
                        id="sltDesde"
                        v-model="formSolicitudQuinchoDto.fechaReserva"
                        v-b-tooltip.hover="'Fecha de solicitud'"
                        locale="es-AR"
                        :min="maxDate"
                        :date-format-options="{ day: 'numeric' , year: 'numeric', month: 'numeric'}"
                        label-help="Use las teclas de flecha para navegar por el calendario"
                        label-current-month="Mes actual"
                        label-next-month="Mes siguiente"
                        label-next-year="Año siguiente"
                        label-no-date-selected="Reserva"
                        label-prev-month="Mes anterior"
                        label-prev-year="Año anterior"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio</small>
                    </div>
                    <!--<cleave
                        id="dateFechareserva"
                        v-model="formSolicitudQuinchoDto.fechaReserva"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :raw="true"
                        :options="options.date"
                        autocomplete="off"
                        placeholder="fecha reserva DD/MM/AAAA"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Campo obligatorio
                      </small>
                    </div>-->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="telefonoContacto"
                  label="Teléfono contacto"
                >
                  <validation-provider
                    name="telefonoContacto"
                    rules="required|max:13"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="inputDni"
                        v-model="formSolicitudQuinchoDto.telContacto"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        type="number"
                        placeholder=""
                        @input="(e) => checkOnlyNumber(e)"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio.
                      </small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 10 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label-for="cantidadPersonas"
                  label="Cantidad de personas"
                >
                  <validation-provider
                    name="cantidadPersonas"
                    rules="required|max:4"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="inputDni"
                        v-model="formSolicitudQuinchoDto.cantPersonas"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        type="number"
                        placeholder=""
                        @input="(e) => checkOnlyNumber(e)"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio.
                      </small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 4 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label-for="horario"
                  label="Horario"
                >
                  <validation-provider
                    name="horario"
                    rules="required|max:10"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectHorario"
                        v-model="formSolicitudQuinchoDto.horario"
                        :options="listaHorario"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="p-0 ">
              <b-col
                md="12"
                class="p-0"
              >
                <b-card-actions
                  class="p-0"
                  title="Observaciones"
                  action-collapse
                >
                  <validation-provider
                    name="observa"
                    rules="required|min:4|max:1000"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-textarea
                        id="textAreaRequisitos"
                        v-model="formSolicitudQuinchoDto.observaciones"
                        no-auto-shrink
                        placeholder="Escriba aquí alguna observación"
                        :state="errors.length > 0 ? false:null"
                        rows="4"
                        maxlength="1000"
                      />
                      <b-row>
                        <b-col md="6">
                          <small
                            v-if="failedRules.required"
                            class="text-danger"
                          >Campo obligatorio
                          </small>
                          <small
                            v-if="failedRules.min"
                            class="text-danger"
                          >Minimo 4 caracteres
                          </small>
                        </b-col>
                        <b-col md="6">
                          <contador
                            :maximo="1000"
                            :texto="formSolicitudQuinchoDto.observaciones"
                            :tipo="'CONTADOR'"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </validation-provider>
                </b-card-actions>
              </b-col>
            </b-row>
            <br>
          </div>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnBackPanelBeneficios"
                v-b-tooltip.hover
                title="Volver al panel de benficios"
                variant="light"
                squared
                :disabled="isWorking"
                @click="()=>backPanelBeneficios()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="showSave"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Generar solicitud"
                type="submit"
                :disabled="isWorking"
                @click.prevent="validationFormSolicitudQuincho"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  required, confirmed, email, max,
  min,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { solicitudResource } from '@/services/solicitudResource'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import Cleave from 'vue-cleave-component'

import {
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Contador from '@/views/util/Contador.vue'

export default {
  components: {
    BCardActions,
    ValidationProvider,
    // Cleave,
    ValidationObserver,
    Contador,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    beneficio: {
      type: Object,
      required: true,
      default: undefined,
    },
    solicitudesPrevias: {
      type: Array,
      required: true,
      default: undefined,
    },
    categoriaSolicitante: {
      type: Array,
      required: true,
      default: undefined,
    },
    afiliado: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      showModalUploadFile: false,

      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '1',
        blur: '5px',
        message: '',
      },
      listaHorario: [
        { value: null, text: 'Seleccione...' },
        { value: 'DIURNO', text: 'DIURNO' },
        { value: 'NOCTURNO', text: 'NOCTURNO' },
      ],
      isWorking: false,
      required,
      confirmed,
      email,
      max,
      min,
      cargando: false,
      cargandoRoles: false,
      nameModulo: 'SOLICITUD_BENEFICIO',
      beneficioDto: null,
      afiliadoDetalle: null,
      solicitudes: [],
      formSolicitudQuinchoDto: {
        idSolicitudQuincho: null,
        usuarioId: null,
        beneficioId: null,
        solicitanteCuil: null,
        beneficiarioNombre: null,
        beneficiarioDni: null,
        beneficiarioFechaNac: null,
        categoriaSolicitante: null,
        solicitudEstado: null,
        delegacionId: null,
        solicitanteDelegacion: null,
        solicitanteDeleExtCode: null,
        solicitanteNroAfiliado: null,
        estadoCodigo: null,
        fechaReserva: null,
        telContacto: null,
        cantPersonas: null,
        horario: null,
        observaciones: null,
      },
      fieldsSolicitantesTable: [
      ],
      progressData: {
        progressSolicitudes: 0,
        progressSolicitudesDone: 0,
        progressAdjuntos: 0,
        progressAdjuntosDone: 0,
      },
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
        number: {
          numeral: false,
          blocks: [18],
          numeralPositiveOnly: true,
          numericOnly: true,
          numeralDecimalMark: '',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    maxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return maxDate
    },
    showSave() {
      return this.permisosIns.includes(this.nameModulo)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
    nombreUsuario() {
      if (this.currentUser) {
        return `${this.currentUser.nombre} ${this.currentUser.apellido}`
      }
      return 'S/D'
    },
  },
  created() {
    this.armarSolicitudQuinchoDto()
  },
  methods: {
    armarSolicitudQuinchoDto() {
      this.formSolicitudQuinchoDto.beneficioId = this.beneficio.id
      this.formSolicitudQuinchoDto.solicitanteCuil = this.currentUser.cuil
      this.formSolicitudQuinchoDto.beneficiarioNombre = this.nombreUsuario
      this.formSolicitudQuinchoDto.beneficiarioDni = this.afiliado.identity
      this.formSolicitudQuinchoDto.beneficiarioFechaNac = this.formatDateToJavaT(this.afiliado.birthdate, 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm')
      // codigo 1 es Afiliado
      const catSol = this.categoriaSolicitante.filter(obj => obj.codigo === 1)[0]
      this.formSolicitudQuinchoDto.categoriaSolicitante = catSol
      this.formSolicitudQuinchoDto.delegacionId = this.currentUser.delegacion.id
      if (this.afiliado.affiliateDelegationName !== null) {
        this.formSolicitudQuinchoDto.solicitanteDelegacion = this.afiliado.affiliateDelegationName
      }
      if (this.afiliado.affiliateDelegationCode !== null) {
        this.formSolicitudQuinchoDto.solicitanteDeleExtCode = this.afiliado.affiliateDelegationCode
      }
      if (this.afiliado.code !== null) {
        this.formSolicitudQuinchoDto.solicitanteNroAfiliado = this.afiliado.code
      }
    },
    dniSolicitud(item) {
      return item.dni != null ? item.dni : 'DNI obligatorio'
    },
    getColorEstado(valido) {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      let color = whiteBlack
      if (valido === false) {
        // Rechazada
        color = '#FC1C1C'
      } else {
        color = 'grey'
      }
      return color
    },
    formatearFechaNac(row) {
      let fecha = '-'
      if (row.item.fechaNacimiento !== null) {
        fecha = this.formatDateTable(row.item.fechaNacimiento, 'DD/MM/YYYY')
      }
      return fecha
    },

    generarSolicitudQuincho() {
      this.overlayConfig.message = 'Generando solicitud ...'
      this.isWorking = true
      const solquinchoDto = JSON.parse(JSON.stringify(this.formSolicitudQuinchoDto))
      solquinchoDto.fechaReserva = this.formatDateToJavaT(solquinchoDto.fechaReserva, 'YYYY-MM-DD', 'DD-MM-YYYY HH:mm')
      solicitudResource().generarSolicitudQuincho(solquinchoDto).then(() => {
        this.isWorking = false
        this.showMessageSolicitudesGeneradas('Solicitudes', 'success', 'Solicitud quincho generada')
      }).catch(error => {
        this.isWorking = false
        this.overlayConfig.message = ''
        this.showMessageSolicitudesGeneradas('Solicitudes', 'error', 'Error al generar la solicitud. Intente más tarde nuevamente.')
        console.error('error', error)
      })
    },
    checkOnlyNumber(val) {
      const reg = new RegExp('^[0-9]*$')
      return reg.test(val)
    },
    validationFormSolicitudQuincho() {
      this.$refs.formSolicitudQuincho.validate().then(success => {
        if (success) {
          // this.generarSolicitudes()
          this.generarSolicitudQuincho()
          // this.generarSolicitudesMultiplesLlamadas()
        }
      })
    },
    backPanelBeneficios() {
      this.$router.push('/panelbeneficios')
    },
    showMessageSolicitudesGeneradas(titleMessage, icon_, message) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${message}</p>`
      this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        html: htmlText,
        icon: icon_,
        background: this.swalConfig.background,
        confirmButtonText: 'Cerrar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.backPanelBeneficios()
        }
      })
    },
  },
}
</script>
