<template>
  <div>
    <b-row
      v-if="!allOperationsDone"
      class="mt-5"
    >
      <b-col>
        <!--
        allOperationsDone{{ allOperationsDone }}<br>
        findSolcitidutesPreviasDone{{ findSolcitidutesPreviasDone }}<br>
        findBeneficioDone{{ findBeneficioDone }}<br>
        findAfiliadoDetalleDone{{ findAfiliadoDetalleDone }}<br>
        -->
        <b-overlay
          :show="!allOperationsDone "
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          spinner-small
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-5">
              <div style="text-align: center">
                <span class="spinner-border p-5" />
              </div>
              <div style="text-align: center">
                <small> Buscando afiliado </small>
                <font-awesome-icon
                  v-if="findAfiliadoDetalleDone && !errorfindAfiliadoDetalle"
                  icon="fa-solid fa-check"
                />
                <font-awesome-icon
                  v-if="findAfiliadoDetalleDone && errorfindAfiliadoDetalle"
                  icon="fa-solid fa-xmark"
                />
                <br>
                <small> Buscando beneficios </small>
                <font-awesome-icon
                  v-if="findBeneficioDone && !errorFindBeneficio"
                  icon="fa-solid fa-check"
                />
                <font-awesome-icon
                  v-if="findBeneficioDone && errorFindBeneficio"
                  icon="fa-solid fa-xmark"
                />

                <br>
                <small> Buscando solicitudes </small>
                <font-awesome-icon
                  v-if="findSolcitidutesPreviasDone && !errorFindSolicitudesPrevias"
                  icon="fa-solid fa-check"
                />
                <font-awesome-icon
                  v-if="findSolcitidutesPreviasDone && errorFindSolicitudesPrevias"
                  icon="fa-solid fa-xmark"
                />
                <br>

              </div>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <b-card v-if="!noErrors">
      <b-row class="justify-content-md-center">

        <feather-icon
          slot="button-content"
          :icon="processFailed? 'AlertTriangleIcon': 'InfoIcon'"
          size="50"
        />

      </b-row>
      <b-row class="justify-content-md-center">
        <span v-if="failedAfiliaiteValidation"> Para solicitar beneficios tu afiliación debe estar activa </span>
        <span v-if="errorWs"> No hemos podido validar tu estado de afiliación. Intentalo más tarde </span>
        <span v-if="processFailed"> Error de comunicación. Intente mas tarde </span>
        <div><span v-if="errorFindBeneficio">Beneficio no encontrado</span>
          <br><br>
          <b-button
            variant="primary"
            class="container"
            :to="{path:'/inicio'}"
          >
            Volver al Inicio
          </b-button>
        </div>
        <span v-if="errorFindSolicitudesPrevias"> Error al recuperar solicitudes previas </span>
      </b-row>
      <hr>
      <b-row class="justify-content-md-center">
        <b-button
          v-if="!noErrors && !errorFindBeneficio"
          squared
          size="sm"
          variant="outline-secondary"
          @click="reintentar()"
        >
          Reintentar
        </b-button>
      </b-row>
    </b-card>
    <validation-observer ref="formSolicitudGenerico">
      <b-form>
        <b-card v-if="allOperationsDone && !validations && noErrors">
          <solicitud-kit
            v-if="mostrarSolicitudKit"
            :beneficio="beneficioDto"
            :solicitudes-previas="solicitudesPrevias"
            :afiliado="afiliadoDetalle"
            :categoria-solicitante="categoriaSolicitante"
          />
          <solicitud-quincho
            v-if="mostrarSolicitudQuincho"
            :beneficio="beneficioDto"
            :solicitudes-previas="solicitudesPrevias"
            :afiliado="afiliadoDetalle"
            :categoria-solicitante="categoriaSolicitante"
          />
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { solicitudResource } from '@/services/solicitudResource'
import { afiliadoResource } from '@/services/afiliadoResource'
import { mapGetters } from 'vuex'
import {
  required, confirmed, email, max,
  min,
} from '@validations'
import {
  BENE_TIPO_CON_PRODUCTO,
  BENE_TIPO_CON_PRODUCTO_PERPETUO,
  BENE_TIPO_QUINCHO,
  BENE_TIPO_CON_PRODUCTO_PANIALES,
} from '@/utils/codigoTipoBeneConst'
import SolicitudKit from './SolicitudKit.vue'
import SolicitudQuincho from './SolicitudQuincho.vue'

export default {
  components: {
    SolicitudKit,
    SolicitudQuincho,
  },
  props: {
    beneficioId: {
      type: String,
      required: true,
      default: undefined,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
      },
      selected: true,
      isWorking: false,
      required,
      confirmed,
      email,
      max,
      min,
      cargando: false,
      cargandoRoles: false,
      nameModulo: 'SOLICITUD_BENEFICIO',
      beneficioDto: null,
      afiliadoDetalle: null,
      solicitudesPrevias: [],
      categoriaSolicitante: [],
      failedAfiliaiteValidation: false,
      errorWs: false,
      processFailed: false,
      errorfindAfiliadoDetalle: null,
      errorFindBeneficio: false,
      errorFindSolicitudesPrevias: null,
      verificacacionesSolicitudesPreviasTerminadas: false,
      findSolcitidutesPreviasDone: false,
      findBeneficioDone: false,
      findAfiliadoDetalleDone: false,
      BENE_TIPO_CON_PRODUCTO,
      BENE_TIPO_CON_PRODUCTO_PERPETUO,
      BENE_TIPO_QUINCHO,
      BENE_TIPO_CON_PRODUCTO_PANIALES,

    }
  },
  computed: {
    mostrarSolicitudKit() {
      return this.beneficioDto !== null && (this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO
        || this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO || this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) && this.afiliadoDetalle !== null && this.allOperationsDone
    },
    mostrarSolicitudQuincho() {
      return this.beneficioDto !== null && this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_QUINCHO && this.afiliadoDetalle !== null && this.allOperationsDone
    },
    noErrors() {
      let noErrors = false
      if (!this.errorfindAfiliadoDetalle && !this.errorFindBeneficio && !this.errorFindSolicitudesPrevias) {
        noErrors = true
      }
      return noErrors
    },
    validations() {
      if (!this.errorWs && !this.failedAfiliaiteValidation && !this.processFailed) {
        return false
      }
      return true
    },
    allOperationsDone() {
      return this.findSolcitidutesPreviasDone && this.findBeneficioDone && this.findAfiliadoDetalleDone
    },
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
  },
  created() {
    this.iniciar()
    // this.usuarioDto = JSON.parse(JSON.stringify(this.usuario))
  },
  methods: {
    iniciar() {
      this.findAfiliadoDetalle()
      this.getAllCategoriaSolicitante()
    },
    reintentar() {
      this.findSolcitidutesPreviasDone = false
      this.findAfiliadoDetalleDone = false
      this.findBeneficioDone = false
      this.failedAfiliaiteValidation = false
      this.errorWs = false
      this.processFailed = false
      this.errorfindAfiliadoDetalle = null
      this.errorFindBeneficio = null
      this.errorFindSolicitudesPrevias = null
      this.iniciar()
      // window.location.reload()
    },
    findSolicitudConProductoPrevias(beneficioId) {
      const listaDniAfiliadoFamilia = []
      if (this.afiliadoDetalle.identity && this.afiliadoDetalle.identity != null) {
        listaDniAfiliadoFamilia.push(this.afiliadoDetalle.identity)
      }

      if (this.afiliadoDetalle.affiliateFamily !== null && this.afiliadoDetalle.affiliateFamily.length) {
        this.afiliadoDetalle.affiliateFamily.forEach(miembro => {
          if (miembro.identity && miembro.identity !== null) {
            listaDniAfiliadoFamilia.push(miembro.identity)
          }
        })
      }
      this.errorFindSolicitudesPrevias = false
      this.overlayConfig.message = 'Verificando solicitudes previas'
      if (beneficioId !== undefined) {
        solicitudResource().getSolicitudesFilter(beneficioId, listaDniAfiliadoFamilia)
          .then(resultFindSolicitudesByBeneficioId => {
            this.solicitudesPrevias = [...resultFindSolicitudesByBeneficioId]
            this.findSolcitidutesPreviasDone = true
          }).catch(error => {
            this.errorFindSolicitudesPrevias = true
            this.findSolcitidutesPreviasDone = true
            console.error(error)
          })
      } else {
        this.findSolcitidutesPreviasDone = true
      }
    },
    findSolicitudQuinchosPrevia(beneficioId) {
      const solicitanteCuil = this.currentUser.cuil
      solicitudResource().getSolicitudesPrevQuincho(beneficioId, solicitanteCuil).then(() => {
        this.findSolcitidutesPreviasDone = true
      }).catch(error => {
        console.error('error', error)
        this.findSolcitidutesPreviasDone = true
      })
    },
    findSolcitidutesPrevias(beneficioId) {
      if (this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO || this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
        this.findSolicitudConProductoPrevias(beneficioId)
      } else if (this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_QUINCHO) {
        this.findSolicitudQuinchosPrevia(beneficioId)
      } else if (this.beneficioDto.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
        this.solicitudesPrevias = []
        this.findSolcitidutesPreviasDone = true
      }
    },
    findBeneficio(beneficioId) {
      this.overlayConfig.message = 'Recuperando datos de beneficio'
      if (beneficioId !== undefined) {
        solicitudResource().findBeneficioDetalle(beneficioId)
          .then(resultFindBeneficioById => {
            this.beneficioDto = { ...resultFindBeneficioById }
            this.findSolcitidutesPrevias(this.beneficioId)
            this.findBeneficioDone = true
          }).catch(error => {
            this.errorFindBeneficio = true
            this.findBeneficioDone = true
            this.findSolcitidutesPreviasDone = true
            console.error(error)
          })
      } else {
        // this.title = 'Nuevo usuario'
        this.findBeneficioDone = true
      }
    },
    findAfiliadoDetalle() {
      this.overlayConfig.message = 'Recuperando datos de afiliado'
      if (afiliadoResource !== undefined) {
        afiliadoResource().findAfiliadoDetalle()
          .then(resultFindAfiliadoDetalle => {
            this.afiliadoDetalle = { ...resultFindAfiliadoDetalle }
            this.findBeneficio(this.beneficioId)
            this.findAfiliadoDetalleDone = true
          }).catch(error => {
            this.errorfindAfiliadoDetalle = true
            this.findSolcitidutesPreviasDone = true
            this.findBeneficioDone = true
            this.findAfiliadoDetalleDone = true
            if (error !== undefined) {
              if (error.data.error.includes('Error servicio WS')) {
                this.errorWs = true
                console.error('Error servicio WS')
              } else if (error.data.code.includes('"INTERNAL_SERVER_ERROR"')) {
                console.error('INTERNAL_SERVER_ERROR')
              } else {
                this.failedAfiliaiteValidation = true
              }
            } else {
              this.processFailed = true
            }
            console.error(error)
          })
      } else {
        // this.title = 'Nuevo usuario'
        this.findSolcitidutesPreviasDone = true
        this.findAfiliadoDetalleDone = true
      }
    },
    getAllCategoriaSolicitante() {
      solicitudResource().getAllCategoriaSolicitante()
        .then(resultGetAllCategoriaSolicitante => {
          this.categoriaSolicitante = [...resultGetAllCategoriaSolicitante]
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
        })
    },
    validationFormPanelBeneficios() {
      this.$refs.solicitudGenerico.validate().then(success => {
        if (success) {
          // this.actionUsuario()
        }
      })
    },
  },
}
</script>
<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
</style>
